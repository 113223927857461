.scroll-container[data-v-e2aaf468] {
  height: 350px;
  /* 设定固定高度，可根据需要调整 */
  overflow-y: auto;
  padding-left: -20px;
  /* 保持滚动条和对话框右边缘有间距 */
}
.container[data-v-e2aaf468] {
  padding: 0;
}
.container .aggrid-container .aggrid[data-v-e2aaf468] {
  height: 100%;
  width: 100%;
}
.dialog-footer[data-v-e2aaf468] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 20px);
  /* 保持和ag-grid宽度一致 */
}
.create-btn[data-v-e2aaf468] {
  float: left;
}
.cus-link-btn[data-v-e2aaf468]:after {
  border-bottom: 0 !important;
}
.footer-buttons[data-v-e2aaf468] {
  display: flex;
  gap: 10px;
}
.confirm-btn[data-v-e2aaf468] {
  margin-right: -20px;
}
[data-v-e2aaf468] .dialog .el-dialog__body {
  padding: 0 var(--el-dialog-padding-primary);
}

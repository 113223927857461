.assess-table[data-v-12d63ca8] {
  width: 100%;
  margin-top: 8px;
}
.assess-table[data-v-12d63ca8] .rowSpanCell {
  display: flex;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #d9dddf;
}
.assess-table[data-v-12d63ca8] .ag-header-group-cell-label .ag-header-group-text {
  width: 100%;
  text-align: center;
}

.table-container[data-v-7960409f] {
  overflow: hidden;
}
.extra-btn_container[data-v-7960409f] {
  display: flex;
  justify-content: end;
  padding: 8px 0;
}
.add-button[data-v-7960409f] {
  text-align: right;
  padding: 8px 0;
}
.description[data-v-7960409f] {
  list-style: decimal;
}

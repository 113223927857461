.header-level[data-v-b54ad019] {
  width: 80px;
}
.header-container[data-v-b54ad019] {
  display: flex;
  justify-content: space-between;
  flex-direction: var(--b54ad019-flexDirection);
}
.header-container span[data-v-b54ad019] {
  margin-right: 16px;
  font-weight: bolder;
}
.header-container .left-container > div[data-v-b54ad019] {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: var(--b54ad019-margin);
}

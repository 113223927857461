.table-container[data-v-9c478f09] {
  overflow: hidden;
}
.extra-btn_container[data-v-9c478f09] {
  display: flex;
  justify-content: end;
  padding: 8px 0;
}
.detail-form[data-v-9c478f09] {
  margin-top: 8px;
}
.confirm_div[data-v-9c478f09] {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.description[data-v-9c478f09] {
  font-size: 12px;
}
